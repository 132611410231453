import { Exam } from './exam.model';
import { parseIntMember, parseBooleanMember, parseDateMember } from '../utils/parse-functions';
import { Discipline } from './discipline.model';
import { Item } from './item.model';
import { Recomed } from './recomed.model';
import { environment } from '../../../environments/environment';

export class Course {
  id: number;

  number: number;
  title: string;
  view: number;
  rating: number;
  documentsCount: number;
  nb_videos: number;
  contentUpdatedAt: Date;
  updateSummaryContent: string;

  disciplines: Discipline[];

  isFavorite: boolean;
  isTodo: boolean;

  fiche: CourseFiche[];
  notes: string;

  documents: Recomed[];
  documentsViewer: Recomed[];
  documentsFiche: Recomed[];

  exams: Exam[];

  updatedAt: Date;
  isOriginal: boolean;
  grade: number;
  userGrade: number;

  next: number;
  prev: number;

  objectifs: Objectif[];
  content: string;

  itemFiles: ItemFile[];
  newDocUploaded: boolean;

  rubriques: Rubrique[];

  constructor(input: any) {
    parseIntMember(input, 'id');

    parseIntMember(input, 'number');
    parseIntMember(input, 'view');
    parseIntMember(input, 'rating');
    parseIntMember(input, 'documentsCount');
    parseIntMember(input, 'nb_videos');
    parseDateMember(input, 'contentUpdatedAt');

    parseBooleanMember(input, 'isFavorite');
    parseBooleanMember(input, 'isTodo');

    parseDateMember(input, 'updatedAt');
    parseBooleanMember(input, 'isOriginal');
    parseIntMember(input, 'grade');
    parseIntMember(input, 'userGrade');

    parseIntMember(input, 'next');
    parseIntMember(input, 'prev');

    parseBooleanMember(input, 'newDocUploaded');

    Object.assign(this, input);

    if (this.disciplines) {
      this.disciplines = this.disciplines.map((discipline) => {
        if (discipline.id) return new Discipline(discipline);
        else return new Discipline({ id: discipline });
      });
    } else {
      this.disciplines = [];
    }

    if (this.fiche) {
      this.fiche = this.fiche.map((part) => {
        return new CourseFiche(part);
      });

      this.fiche.forEach((part) => {
        part.body.forEach((subpart) => {
          subpart.isEditing = false;
          if (!subpart.body.length) subpart.body = '';
          subpart.body = subpart.body.replace(/<p>&nbsp;<\/p>/gi, '');
          subpart.body = subpart.body.replace(/<p><strong>&nbsp;<\/strong><\/p>/gi, '');
          subpart.body = subpart.body.replace(/<p><br><\/p>/gi, '');
        });
      });
    } else {
      this.fiche = [];
    }

    if (this.documents) {
      this.documents = this.documents.map((document) => {
        return new Recomed(document);
      });

      this.documentsViewer = [];
      this.documentsFiche = [];

      this.documents.forEach((document) => {
        document.url = decodeURIComponent(document.url);
        if (document.viewerjs) {
          if (document.year) {
            this.documentsFiche.push(document);
          } else {
            this.documentsViewer.push(document);
          }
        }
      });

      this.documentsFiche = this.documentsFiche.sort((a, b) => {
        return a.year > b.year ? -1 : 1;
      });
    } else {
      this.documents = [];
    }

    if (this.exams) {
      this.exams = this.exams.map((elt) => {
        return new Exam(elt);
      });
    } else {
      this.exams = [];
    }

    if (this.objectifs) {
      this.objectifs = this.objectifs.map((objectif) => {
        return new Objectif(objectif);
      });
    } else {
      this.objectifs = [];
    }

    if (this.itemFiles) {
      this.itemFiles = this.itemFiles.map((itemFile) => {
        return new ItemFile(itemFile);
      });
    } else {
      this.itemFiles = [];
    }

    if (this.rubriques) {
      this.rubriques = this.rubriques.map((rubrique) => {
        return new Rubrique(rubrique);
      });
    } else {
      this.rubriques = [];
    }
  }

  get isSDD() {
    return (
      this.disciplines.find((discipline) => discipline.id === 42) &&
      environment.defaultLanguage === 'fr'
    );
  }
}

export class Objectif {
  id: number;
  name: string;

  constructor(input: any) {
    parseIntMember(input, 'id');

    Object.assign(this, input);
  }
}

export class CourseFiche {
  body: {
    body: string;
    h1?: string;
    isEditing: boolean;
  }[];
  h1: string;

  constructor(input: any) {
    Object.assign(this, input);
  }
}

export class ItemFile {
  fileUrl: string;
  year: number;

  constructor(input: any) {
    parseIntMember(input, 'year');
    Object.assign(this, input);
  }
}

export class Rubrique {
  id: number;
  title: string;

  notions: Notion[];
  item?: Item;

  constructor(input: any) {
    parseIntMember(input, 'id');

    Object.assign(this, input);

    if (this.notions) {
      this.notions = this.notions.map((notion) => {
        return new Notion(notion);
      });
    } else {
      this.notions = [];
    }

    if (this.item) {
      this.item = new Item(this.item);
    }
  }
}

export class Notion {
  id: number;
  title: string;
  rang: 'A' | 'B' | 'C' | 'Z';
  rubrique?: Rubrique;

  constructor(input: any) {
    parseIntMember(input, 'id');

    Object.assign(this, input);

    if (this.rubrique) {
      this.rubrique = new Rubrique(this.rubrique);
    }
  }

  get color() {
    if (this.rang === 'A') {
      return 'bg-r2c-black';
    } else if (this.rang === 'B') {
      return 'bg-r2c-blue';
    } else {
      return 'bg-r2c-green';
    }
  }

  get rangDisplay() {
    if (this.rang === 'Z') {
      return 'C';
    } else {
      return this.rang;
    }
  }
}
