import { Injectable } from '@angular/core';

import { Device } from '@capacitor/device';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';

import { LocaleKeys } from '../../shared/utils/locale-keys';
import { BehaviorSubject } from 'rxjs';

declare const fbq: any;
declare const gtag: any;

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  showCookies = false;

  constructor() {}

  init() {
    Device.getInfo().then((infos) => {
      if (infos.platform === 'ios') {
        this.initIos();
      } else {
        this.initOtherIos();
      }
    });
  }

  initOtherIos() {
    if (localStorage.getItem(LocaleKeys.cookiesAccepted) === '1') {
      this.grantConsent();
    } else if (localStorage.getItem(LocaleKeys.cookiesAccepted) !== '-1') {
      this.showCookies = true;
    } else {
      // les cookies ont déjà été refusées : on regarde c'était quand et si > 3 jours on redemande
      const lastCookiesRefusedDate = localStorage.getItem(LocaleKeys.cookiesLastRefusedDate);
      const now = new Date();
      if (lastCookiesRefusedDate) {
        const refusedDate = new Date(parseInt(lastCookiesRefusedDate, 10));

        const diffTime = Math.abs(refusedDate.getTime() - now.getTime());
        const diffDays = diffTime / (1000 * 60 * 60 * 24);

        if (diffDays > 3) {
          this.showCookies = true;
        }
      } else {
        this.showCookies = true;
      }
    }
  }

  initIos() {
    AppTrackingTransparency.getStatus().then((status) => {
      if (status.status === 'authorized') {
        this.grantConsent();
      } else {
        AppTrackingTransparency.requestPermission().then((statusRequest) => {
          if (statusRequest.status === 'authorized') {
            this.grantConsent();
          }
        });
      }
    });
  }

  acceptCookies() {
    localStorage.setItem(LocaleKeys.cookiesAccepted, '1');
    this.grantConsent();
    this.showCookies = false;
  }

  refuseCookies() {
    const now = new Date();
    localStorage.setItem(LocaleKeys.cookiesAccepted, '-1');
    localStorage.setItem(LocaleKeys.cookiesLastRefusedDate, now.getTime().toString());
    this.showCookies = false;
  }

  grantConsent() {
    this.consentFacebookPixel();
    this.consentTagManager();
  }

  consentFacebookPixel() {
    fbq('consent', 'grant');
  }

  consentTagManager() {
    gtag('consent', 'update', {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
  }
}
