<div
  class="w-full sm:w-[250px] lg:w-[320px] mr-0 sm:mr-8 md:mr-16 shrink-0 shadow flex flex-col items-stretch"
>
  <div class="gradient rounded-t px-6 lg:px-16 py-16 grid grid-cols-1 gap-y-10">
    <app-user-sunray
      [user]="user && socialStats ? user : null"
      [userRanking]="userRanking"
      [rankingLevels]="rankingLevels"
      [canChangePhoto]="user?.id == userService.loggedUserValue?.id"
    ></app-user-sunray>

    <div class="h-16" *ngIf="languageService.challengeEnabled">
      <div class="flex space-x-4 text-11" *ngIf="socialStats">
        <div
          class="badge-followers"
          (click)="socialStats?.followings > 0 ? openModalListFriends('followings') : null"
        >
          <strong>{{ socialStats ? socialStats.followings : '-' }}</strong>
          <span>{{ 'twit.abonnements' | translate }}</span>
        </div>

        <div
          class="badge-followers"
          (click)="socialStats?.followers > 0 ? openModalListFriends('followers') : null"
        >
          <strong>{{ socialStats ? socialStats.followers : '-' }}</strong>
          <span>{{ 'twit.abonnes' | translate }}</span>
        </div>
      </div>
    </div>

    <div class="space-y-6" *ngIf="languageService.challengeEnabled">
      <ng-container *ngIf="userId == userService.loggedUserValue?.id">
        <div
          class="btn-nnki btn-nnki-white btn-nnki-100 border-0"
          *ngIf="waitingRequests > 0"
          [matBadge]="waitingRequests"
          matBadgeOverlap="true"
          matBadgeSize="small"
          (click)="openModalListFriends('waitingRequest')"
        >
          {{ 'friend.en_attente' | translate }}
        </div>

        <div *ngIf="!waitingRequests" class="btn-nnki btn-nnki-dummy"></div>

        <!-- <div
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          class="btn-nnki btn-nnki-icon-left btn-nnki-white btn-nnki-100 border-0 text-11 text-facebook"
          *ngIf="!isFacebookImporting"
          (click)="facebookImport()"
        >
          <svg-icon
            src="assets/images/svg-icons/icon-facebook.svg"
            [svgStyle]="{ 'width.px': '21' }"
          ></svg-icon>
          <span>{{ 'settings.facebook_import' | translate }}</span>
        </div>

        <div class="spinner_100_container">
          <app-loader-dots *ngIf="isFacebookImporting"></app-loader-dots>
        </div> -->
      </ng-container>

      <ng-container *ngIf="userId != userService.loggedUserValue?.id">
        <ng-container *ngIf="user">
          <ng-container *ngIf="!user.followIsLoading">
            <div
              matRipple
              [matRippleCentered]="false"
              [matRippleUnbounded]="true"
              [matRippleRadius]="50"
              class="btn-nnki btn-nnki-primary btn-nnki-100"
              *ngIf="!user.followed_by_user && !user.followed_by_user_need_approval"
              (click)="ecniTweetsService.follow(user)"
            >
              {{ 'twit.sabonner' | translate }}
            </div>

            <div
              matRipple
              [matRippleCentered]="false"
              [matRippleUnbounded]="true"
              [matRippleRadius]="50"
              class="btn-nnki btn-nnki-white btn-nnki-100"
              *ngIf="!user.followed_by_user && user.followed_by_user_need_approval"
              (click)="ecniTweetsService.unfollow(user)"
            >
              {{ 'friend.attente' | translate }}
            </div>

            <div
              matRipple
              [matRippleCentered]="false"
              [matRippleUnbounded]="true"
              [matRippleRadius]="50"
              class="btn-nnki btn-nnki-white btn-nnki-100"
              *ngIf="user.followed_by_user"
              (click)="ecniTweetsService.unfollow(user)"
            >
              {{ 'twit.se_desabonner' | translate }}
            </div>
          </ng-container>

          <div class="spinner_100_container" *ngIf="user.followIsLoading">
            <app-loader-dots></app-loader-dots>
          </div>
        </ng-container>

        <div *ngIf="!user" class="btn-nnki btn-nnki-dummy"></div>

        <div
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          class="btn-nnki btn-nnki-purple btn-nnki-icon-left btn-nnki-100 btn-nnki-uppercase btn-nnki-heavy"
          *ngIf="isApplicationService.isPremiumEnabled"
          (click)="modalService.openModalChallenge(user)"
        >
          <svg-icon
            src="assets/images/svg-icons/icon-challenge.svg"
            [svgStyle]="{ 'width.px': '22' }"
          ></svg-icon>
          {{ 'challenge.defier' | translate }}
        </div>

        <div
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          class="btn-nnki btn-nnki-primary btn-nnki-icon-left btn-nnki-100 btn-nnki-uppercase btn-nnki-heavy"
          (click)="createTemporaryMessageGroup([user])"
        >
          <svg-icon
            src="assets/images/svg-icons/icon-chat.svg"
            [svgStyle]="{ 'width.px': '22' }"
          ></svg-icon>
          {{ 'twit.ecrire' | translate }}
        </div>
      </ng-container>
    </div>
  </div>

  <div
    class="grow bg-theme-white rounded-b py-10 px-8 sm:px-16"
    *ngIf="languageService.challengeEnabled"
  >
    <div class="text-16 font-extrabold mb-16">
      {{ 'challenge.palmares' | translate }}
    </div>

    <div
      class="mb-12"
      *ngIf="rankingLevels"
      appGradientHidden
      [displayedInit]="rankingLevels.length <= 4"
      [heightInit]="250"
    >
      <div
        class="flex items-center mb-6 text-14 font-extrabold"
        *ngFor="let level of rankingLevels"
      >
        <img
          src="assets/images/svg-illustrations/icon-challenge-{{ level.level }}.svg"
          class="w-14 mr-3"
        />
        <div class="badge-discipline">{{ level.name }}</div>
      </div>
    </div>

    <div
      class="text-center text-site-main-one text-12 font-semibold cursor-pointer"
      (click)="openModalChallengeBadgeInfos()"
    >
      {{ 'challenge.plus_palmares' | translate }}
    </div>
  </div>
</div>

<div class="flex flex-col grow rounded bg-theme-white shadow">
  <div
    class="bg-theme-gray-seventeen rounded-t py-12 px-8 sm:px-12 flex items-center justify-between"
  >
    <div class="hidden sm:flex">
      <div>
        <div class="relative w-32 h-32">
          <div class="absolute top-0 bottom-0 right-0 left-0">
            <div id="svg_progress-{{ now }}"></div>
          </div>
          <div class="progress-value" *ngIf="userData">
            <strong>{{ userData?.participation * 100 | number : '1.0-0' : 'fr' }}</strong
            >%
          </div>
        </div>
        <div class="progress-label mt-4">
          {{ 'filters.progression' | translate }}
        </div>
      </div>

      <div>
        <div class="relative w-32 h-32">
          <div class="absolute top-0 bottom-0 right-0 left-0">
            <div id="svg_success-{{ now }}"></div>
          </div>
          <div class="progress-value" *ngIf="userData">
            <strong>{{ userData?.success * 100 | number : '1.0-0' : 'fr' }}</strong
            >%
          </div>
        </div>
        <div class="progress-label mt-4">
          {{ 'training.reussite' | translate }}
        </div>
      </div>
    </div>

    <div
      class="grow ml-0 sm:ml-8 grid grid-cols-[min-content_1fr_min-content] md:grid-cols-[1fr_1fr_min-content] gap-6 items-center whitespace-nowrap"
      *ngIf="languageService.challengeEnabled"
    >
      <div class="victory-bar sm:hidden">
        <div class="victory-label">
          {{ 'filters.progression' | translate }}
        </div>
        <div class="ecni_progress_bar">
          <div
            class="progress_bar_in -purple"
            [style.width.%]="userData?.participation * 100"
          ></div>
        </div>
        <div class="victory-value">
          <ng-container *ngIf="userData">
            {{ userData?.participation * 100 | number : '1.0-0' : 'fr' }}%
          </ng-container>
        </div>
      </div>

      <div class="victory-bar sm:hidden">
        <div class="victory-label">
          {{ 'training.reussite' | translate }}
        </div>
        <div class="ecni_progress_bar">
          <div class="progress_bar_in -blue" [style.width.%]="userData?.success * 100"></div>
        </div>
        <div class="victory-value">
          <ng-container *ngIf="userData">
            {{ userData?.success * 100 | number : '1.0-0' : 'fr' }}%
          </ng-container>
        </div>
      </div>

      <div class="victory-bar">
        <div class="victory-label">
          {{ 'challenge.victoires' | translate }}
        </div>
        <div class="ecni_progress_bar">
          <div
            class="progress_bar_in -green"
            [style.width.%]="
              (userVictories?.total ? userVictories?.wins / userVictories?.total : 0) * 100
            "
          ></div>
        </div>
        <div class="victory-value">
          <ng-container *ngIf="userData">
            {{
              (userVictories?.total ? userVictories?.wins / userVictories?.total : 0) * 100
                | number : '1.0-0' : 'fr'
            }}% <small>({{ userVictories?.wins | number : '1.0-0' : 'fr' }})</small>
          </ng-container>
        </div>
      </div>

      <div class="victory-bar">
        <div class="victory-label">
          {{ 'challenge.defaites' | translate }}
        </div>
        <div class="ecni_progress_bar">
          <div
            class="progress_bar_in -red"
            [style.width.%]="
              (userVictories?.total ? userVictories?.loses / userVictories?.total : 0) * 100
            "
          ></div>
        </div>
        <div class="victory-value">
          <ng-container *ngIf="userData">
            {{
              (userVictories?.total ? userVictories?.loses / userVictories?.total : 0) * 100
                | number : '1.0-0' : 'fr'
            }}% <small>({{ userVictories?.loses | number : '1.0-0' : 'fr' }})</small>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="grow bg-theme-white rounded-b overflow-hidden">
    <div class="py-8 px-6 sm:px-16 text-16 font-extrabold">
      {{ 'challenge.actualites' | translate }}
    </div>

    <div class="actualities-list">
      <app-challenge-actualities
        [userId]="userId"
        [inModale]="inModale"
      ></app-challenge-actualities>
    </div>
  </div>
</div>
