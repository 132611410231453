export interface ExamType {
  type: string;
  translationKey: string;
  filtersKey?: string;
  icon?: string;
  color: string;
  isConftube: boolean;
  isMulti: boolean;
  isEpreuve: boolean;
  isUnitFolder: boolean;
  hasDisciplineMain: boolean;
  noRetake: boolean;
  inExamFilters: boolean;
  showInProgress: boolean;
  languagesAvailable: string[];
}

export const EXAM_TYPES: ExamType[] = [
  {
    type: 'exam.quizz',
    translationKey: 'examens.ecniquizz',
    filtersKey: 'quizz',
    icon: 'assets/images/illustrations/exams/quizz.svg',
    color: '#2E83F7',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: false,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: ['fr'],
  },
  {
    type: 'exam.quizz.iso',
    translationKey: 'examens.ecniquizz',
    filtersKey: 'quizz.iso',
    icon: 'assets/images/illustrations/exams/quizz.svg',
    color: '#2E83F7',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: false,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: ['it', 'es'],
  },
  {
    type: 'exam.quizz.mmg',
    translationKey: 'examens.quizz_mmg',
    filtersKey: 'quizz.mmg',
    icon: 'assets/images/illustrations/exams/quizz.svg',
    color: '#2E83F7',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: false,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: ['it'],
  },
  {
    type: 'exam.quizz.prepacademy',
    translationKey: 'examens.prepacademy',
    filtersKey: 'quizz.prepacademy',
    icon: 'assets/images/illustrations/exams/prepacademy.svg',
    color: '#EBB77D',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: false,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: ['it'],
  },
  {
    type: 'exam.conftube',
    translationKey: 'examens.conftube',
    filtersKey: 'conftube',
    icon: 'assets/images/illustrations/exams/conftube.svg',
    color: '#FF684C',
    isConftube: true,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: false,
    hasDisciplineMain: true,
    noRetake: true,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: ['fr', 'it', 'es'],
  },
  {
    type: 'exam.masterclass',
    translationKey: 'examens.masterclass',
    filtersKey: 'masterclass',
    color: '#D95E90',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: false,
    hasDisciplineMain: true,
    noRetake: false,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: ['fr', 'it'],
  },
  {
    type: 'exam.lca',
    translationKey: 'examens.lca',
    filtersKey: 'lca',
    icon: 'assets/images/illustrations/exams/lca.svg',
    color: '#EBB77D',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: false,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: ['fr'],
  },
  {
    type: 'exam.imagerie',
    translationKey: 'examens.imagerie',
    filtersKey: 'imagerie',
    icon: 'assets/images/illustrations/exams/imagerie.svg',
    color: '#48F4E7',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: false,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: ['fr', 'it', 'es'],
  },
  {
    type: 'exam.concours',
    translationKey: 'examens.concours',
    filtersKey: 'concours',
    icon: 'assets/images/illustrations/exams/concours.svg',
    color: '#EEB635',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: false,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: ['fr'],
  },
  {
    type: 'exam.concours.quizz',
    translationKey: 'examens.concours',
    filtersKey: 'concours.quizz',
    icon: 'assets/images/illustrations/exams/concours.svg',
    color: '#EEB635',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: false,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: ['it'],
  },
  {
    type: 'exam.concorso.ssm',
    translationKey: 'examens.concorso_ssm',
    filtersKey: 'concorso.ssm',
    icon: 'assets/images/illustrations/exams/concours.svg',
    color: '#EEB635',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: false,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: ['it', 'es'],
  },
  {
    type: 'exam.easy_dp',
    translationKey: 'examens.easy_dp',
    filtersKey: 'easy_dp',
    icon: 'assets/images/illustrations/exams/easy_dp.svg',
    color: '#be4bdb',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: true,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: ['fr'],
  },
  {
    type: 'exam.mini_dp',
    translationKey: 'examens.mini_dp',
    filtersKey: 'mini_dp',
    icon: 'assets/images/illustrations/exams/mini_dp.svg',
    color: '#847AF2',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: true,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: ['fr'],
  },
  {
    type: 'exam.kfp',
    translationKey: 'examens.kfp',
    filtersKey: 'kfp',
    icon: 'assets/images/illustrations/exams/kfp.svg',
    color: '#5BB9FC',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: true,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: ['fr'],
  },
  {
    type: 'exam.tcs',
    translationKey: 'examens.tcs',
    filtersKey: 'tcs',
    icon: 'assets/images/illustrations/exams/tcs.svg',
    color: '#0CC6BF',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: true,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: [],
  },
  {
    type: 'exam.blanche',
    translationKey: 'examens.ecni_blanc',
    filtersKey: 'blanche',
    icon: 'assets/images/illustrations/exams/blanche.svg',
    color: '#49DC50',
    isConftube: false,
    isMulti: true,
    isEpreuve: false,
    isUnitFolder: false,
    hasDisciplineMain: false,
    noRetake: true,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: ['fr'],
  },
  {
    type: 'exam.epreuve',
    translationKey: 'preview.epreuve_maj',
    icon: 'assets/images/illustrations/exams/blanche.svg',
    color: '#49DC50',
    isConftube: false,
    isMulti: false,
    isEpreuve: true,
    isUnitFolder: false,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: false,
    showInProgress: false,
    languagesAvailable: ['fr'],
  },
  {
    type: 'exam.annales',
    translationKey: 'examens.annale',
    filtersKey: 'annales',
    icon: 'assets/images/illustrations/exams/annale.svg',
    color: '#63A5FF',
    isConftube: false,
    isMulti: true,
    isEpreuve: false,
    isUnitFolder: false,
    hasDisciplineMain: false,
    noRetake: true,
    inExamFilters: true,
    showInProgress: true,
    languagesAvailable: ['fr'],
  },
  {
    type: 'exam.epreuveannales',
    translationKey: 'preview.epreuve_maj',
    icon: 'assets/images/illustrations/exams/annale.svg',
    color: '#63A5FF',
    isConftube: false,
    isMulti: false,
    isEpreuve: true,
    isUnitFolder: false,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: false,
    showInProgress: false,
    languagesAvailable: ['fr'],
  },
  {
    type: 'exam.custom',
    translationKey: 'tabs.custom',
    icon: 'assets/images/illustrations/exams/custom.svg',
    color: '#DC97BE',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: false,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: false,
    showInProgress: false,
    languagesAvailable: ['fr', 'it'],
  },
  {
    type: 'exam.type.fake',
    translationKey: 'dossier.dossier',
    color: '#D95E90',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: false,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: false,
    showInProgress: false,
    languagesAvailable: [],
  },
  {
    type: 'exam.ecos',
    translationKey: 'ecos.ecos',
    icon: 'assets/images/svg-icons/icon-ecos.svg',
    color: '#D95E90',
    isConftube: false,
    isMulti: false,
    isEpreuve: false,
    isUnitFolder: false,
    hasDisciplineMain: false,
    noRetake: false,
    inExamFilters: false,
    showInProgress: false,
    languagesAvailable: ['fr'],
  },
];

export function getExamType(name: string) {
  return EXAM_TYPES.find((type) => {
    return type.type === name;
  });
}
