import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import { getExamType } from '../../shared/types/exam-types';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor() {}

  isEcni() {
    if (environment.defaultLanguage === 'fr') {
      return true;
    } else {
      return false;
    }
  }

  isPrepSSM() {
    if (environment.defaultLanguage === 'it') {
      return true;
    } else {
      return false;
    }
  }

  isPrepMIR() {
    if (environment.defaultLanguage === 'es') {
      return true;
    } else {
      return false;
    }
  }

  get siteId() {
    return environment.site_id;
  }

  get siteThemeClass() {
    return `${environment.site_id}-theme-site`;
  }

  get googlePlayAppId() {
    return environment.google_play_id;
  }

  get googlePlayUrl() {
    return `https://play.google.com/store/apps/details?id=${this.googlePlayAppId}&hl=${environment.locale}`;
  }

  get appStoreAppId() {
    return environment.appstore_id;
  }

  get appStoreUrl() {
    return `https://itunes.apple.com/${environment.defaultLanguage}/app/ecni/${this.appStoreAppId}`;
  }

  get facebookUrl() {
    return environment.facebook_url;
  }

  get instagramUrl() {
    return environment.instagram_url;
  }

  get tiktokUrl() {
    return environment.tiktok_url;
  }

  get telegramUrl() {
    return environment.telegram_url;
  }

  get intercomHelpdesk() {
    return environment.intercomHelpdeskUrl;
  }

  get intercomEmail() {
    return `${environment.intercomAppId}@inbound.intercom-mail.com`;
  }

  get defaultLanguage() {
    return environment.defaultLanguage;
  }

  get siteName() {
    return environment.site_name;
  }

  get adminUrl() {
    return environment.admin_url;
  }

  get screenshotEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get abbreviationsEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get methodoEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get premiumButtonOnDashboard() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get showAllIsolatedQuestions() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get showIsolatedQuestionsFolderInfos() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get noAnswerAccepted() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get foldersEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get podcastsEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get majorTestimonies() {
    if (this.isEcni()) {
      return [
        {
          position: 'livreor.1er',
          image: 'assets/images/thumbnails/yannick.jpeg',
          username: 'Yannick',
          infos: 'Major aux EDN 2023-2024 dans 6/13 classements',
          content: `<p>J'ai utilisé EDN depuis la D2.</p><p>Aux EDN, je suis convaincu que le classement est directement corrélé au nombre de QCMs faits en entraînement. Parmi mes sources d’entraînement, j’utilisais EDN.fr, notamment durant mes révisions pour les écrits. Ce site présente l'avantage de la simplicité : l'interface est très bien conçue et il permet de lancer la session d'entraînement que l'on veut en 2 clics. On peut également faire varier sa durée, filtrer par matière ou bien par item, ce qui est très pratique pour faire des tours rapides. Par ailleurs les corrections ont le mérite d'être toujours bien détaillées.</p><p>La banque en elle même est vaste et couvre toutes les matières ou il est parfois difficile de trouver des entraînements plus officiels (annales, concours blancs). Il y a des questions faciles pour ancrer les connaissances comme des questions plus difficiles pour aller taper dans les coins du programme.</p><p>Autre modalité que j'ai utilisé : ce sont les fiches. Félicitations à l'équipe de rédaction qui est chargée de les mettre à jour, les versions récentes que vous postez sont très pertinentes dans leur contenu comme dans le visuel.</p><p>Pour résumer, je dirais que le site est très pratique et m'aura été utile durant mon externat.</p><p>Bon courage à tous dans vos révisions !</p>`,
        },
        {
          position: 'livreor.2e',
          image: 'assets/images/thumbnails/2023.jpg',
          username: 'Hervé',
          infos: '2ème aux ECNi 2023',
          content: `<p>
          J'ai utilisé EDN depuis la D2. Depuis un an, la plateforme a beaucoup évolué avec la R2C, et dans le bon sens.<br />
          Les QI, auparavant très axées sur le détail, sont revenus à un niveau plus accessible, et le mode Ancrage permet de bien stimuler sa mémoire tout au long de l'année, même sur des choses que l'on a pas revu depuis longtemps.<br />
          Je recommande tout particulièrement les Masterclass et les EasyDP, qui sont parfaitement adaptés à la R2C et qui sont proches de ce qui est tombé à l'ECN 2023. Les concours blancs sont aussi très bien fait, collent bien avec ce qui tombe à l'ECN, et permettent d'estimer son classement avec une bonne corrélation avec le classement final.
          <br />Il ne manque plus que la plateforme enrichisse son contenu sur les matières qui tomberont de plus en plus : la génétique, la santé publique, la médecine du travail, la nutrition, etc.. pour que ce soit vraiment complet.<br />
          En bref, merci EDN, c'est cher, mais ça vaut le coup !
        </p>`,
        },
        {
          position: 'livreor.1er',
          image: 'assets/images/thumbnails/marie-2021.png',
          username: 'Marie',
          infos: 'Major aux ECNi 2021',
          content: `<p>
          J'ai commencé à utiliser EDN entre la D3 et la D4, et je m'en suis servie
          régulièrement pendant toute mon année de D4.<br />
          Les EDN quizz permettent d'avoir régulièrement des cas cliniques inédits et
          transversaux, et de se situer grâce au classement.<br />
          J'aimais bien regarder les conftubes, c'est agréable d'avoir des petites vidéos pour
          réviser autrement.<br />
          Et surtout je conseille particulièrement le mode ancrage pour les QCMs qui m'ont
          permis de revoir les notions régulièrement et de m'en souvenir sur le long terme
          !<br />
          Le site offre une grande diversité de supports et je pense que chacun peut s'y
          retrouver selon sa méthode de travail.
        </p>`,
        },
        {
          position: 'livreor.1er',
          image: 'assets/images/thumbnails/marie.png',
          username: 'Marie',
          infos: 'Major aux ECNi 2020',
          content: `<p>
          J'ai commencé à m'entraîner sur EDN des la fin de la D3 et c'est le support que
          j'ai le plus utilisé pendant la D4.<br />
          Les DP et QI sont très poussés ce qui permet à la fois de s'entraîner sur les points
          compliqués du programme et de mettre en évidence des points mal compris, une vraie
          source de motivation pour la poursuite de l'apprentissage !<br />
          La publication de nouveaux dossiers régulièrement permet de rythmer les révisions et
          de situer son niveau grâce au classement publié.<br />
          Les articles de LCA sont variés, balayent tous les types d'études et permettent de
          progresser grâce à une correction détaillée !
        </p>`,
        },
        // {
        //   position: 'livreor.1er',
        //   image: 'assets/images/thumbnails/charles.jpg',
        //   username: 'Charles-Meyer',
        //   infos: 'Major aux ECNi 2019',
        //   content: `<p>
        //   J’ai commencé à utiliser EDN de façon assidue en D3.<br />Cet outil offre une
        //   grande variété de contenu, qu’il s’agisse des questions isolées ou des dossiers
        //   progressifs, dont les corrections sont toujours détaillées.<br />Les concours blancs
        //   et les EDNquizz permettent de s’habituer à des dossiers originaux et surprenants
        //   comme ceux pouvant tomber à l’ECN, et les classements permettent d’avoir une idée de
        //   sa progression.<br />Le mode ancrage mémoriel aide également à organiser ses
        //   révisions autour de ses points faibles, ce qui constitue un atout majeur devant
        //   l’ampleur du programme.<br />Ce site a sans aucun doute contribué à mon résultat
        //   final et je suis heureux de rentrer dans l’équipe des conférenciers !
        // </p>`,
        // },
        {
          position: 'livreor.1er',
          image: 'assets/images/thumbnails/maxime.jpg',
          username: 'Maxime',
          infos: 'Major aux ECNi 2016',
          content: `<p>
          J’ai commencé à utiliser EDN durant ma D3. Un bon moyen de se familiariser avec
          les épreuves informatisées. Les concours blancs nationaux permettent de s’entraîner
          dans des conditions réalistes. Cela permet d’arriver le jour J plus rassuré.<br />
          <br />Bon courage à tous !
        </p>`,
        },
        {
          position: 'livreor.1er',
          image: 'assets/images/thumbnails/mathias.png',
          username: 'Mathias',
          infos: '2e aux ECNi 2020',
          content: `<p>
          Début d'EDN en D3.<br />Les examens publiés régulièrement quel que soit le
          contenu sont galvanisants via le classement apporté.<br />A la retrospective, ceci
          est assez représentatif du résultat final.<br />Les QI et les masterclass permettent
          de ne rien négliger d'une matière.<br />Les LCA sont également pertinentes.<br />Je
          ne recommande ne serait-ce que pour le suivi apporté.
        </p>`,
        },
      ];
    } else if (this.isPrepSSM()) {
      return [];
    } else if (this.isPrepMIR()) {
      return [];
    }
  }

  get majorTestimoniesLanding() {
    if (this.isEcni()) {
      return [
        {
          author: 'Yannick',
          rank: 'Major aux EDN 2023-2024',
          smallQuote:
            'Aux EDN, je suis convaincu que le classement est directement corrélé au nombre de QCMs faits en entraînement.',
          content: `<p>J'ai utilisé EDN depuis la D2.</p><p>Aux EDN, je suis convaincu que le classement est directement corrélé au nombre de QCMs faits en entraînement. Parmi mes sources d’entraînement, j’utilisais EDN.fr, notamment durant mes révisions pour les écrits. Ce site présente l'avantage de la simplicité : l'interface est très bien conçue et il permet de lancer la session d'entraînement que l'on veut en 2 clics. On peut également faire varier sa durée, filtrer par matière ou bien par item, ce qui est très pratique pour faire des tours rapides. Par ailleurs les corrections ont le mérite d'être toujours bien détaillées.</p><p>La banque en elle même est vaste et couvre toutes les matières ou il est parfois difficile de trouver des entraînements plus officiels (annales, concours blancs). Il y a des questions faciles pour ancrer les connaissances comme des questions plus difficiles pour aller taper dans les coins du programme.</p><p>Autre modalité que j'ai utilisé : ce sont les fiches. Félicitations à l'équipe de rédaction qui est chargée de les mettre à jour, les versions récentes que vous postez sont très pertinentes dans leur contenu comme dans le visuel.</p><p>Pour résumer, je dirais que le site est très pratique et m'aura été utile durant mon externat.</p><p>Bon courage à tous dans vos révisions !</p>`,
          picture: 'assets/images/thumbnails/yannick.jpeg',
        },
        {
          author: 'Hervé',
          rank: '2ème aux ECNi 2023',
          smallQuote: `J’ai utilisé EDN depuis la D2`,
          content: `<p>
          J'ai utilisé EDN depuis la D2. Depuis un an, la plateforme a beaucoup évolué avec la R2C, et dans le bon sens.</p><p>
          Les QI, auparavant très axées sur le détail, sont revenus à un niveau plus accessible, et le mode Ancrage permet de bien stimuler sa mémoire tout au long de l'année, même sur des choses que l'on a pas revu depuis longtemps.</p><p>
          Je recommande tout particulièrement les Masterclass et les EasyDP, qui sont parfaitement adaptés à la R2C et qui sont proches de ce qui est tombé à l'ECN 2023. Les concours blancs sont aussi très bien fait, collent bien avec ce qui tombe à l'ECN, et permettent d'estimer son classement avec une bonne corrélation avec le classement final.</p><p>
          Il ne manque plus que la plateforme enrichisse son contenu sur les matières qui tomberont de plus en plus : la génétique, la santé publique, la médecine du travail, la nutrition, etc.. pour que ce soit vraiment complet.</p><p>
          En bref, merci EDN, c'est cher, mais ça vaut le coup !
          </p>`,
          picture: 'assets/images/thumbnails/2023.jpg',
        },
        {
          author: `Marie`,
          rank: `Major aux ECNi 2021`,
          smallQuote: `Chacun peut s'y retrouver selon sa méthode de travail`,
          content: `<p>
            J'ai commencé à utiliser EDN entre la D3 et la D4, et je m'en suis servie
            régulièrement pendant toute mon année de D4.</p><p>
            Les EDNquizz permettent d'avoir régulièrement des cas cliniques inédits et
            transversaux, et de se situer grâce au classement.<br />
            J'aimais bien regarder les conftubes, c'est agréable d'avoir des petites vidéos pour
            réviser autrement.</p><p>
            Et surtout je conseille particulièrement le mode ancrage pour les QCMs qui m'ont
            permis de revoir les notions régulièrement et de m'en souvenir sur le long terme
            !</p><p>
            Le site offre une grande diversité de supports et je pense que chacun peut s'y
            retrouver selon sa méthode de travail.
          </p>`,
          picture: 'assets/images/thumbnails/marie-2021.png',
        },
        {
          author: `Marie`,
          rank: `Major aux ECNi 2020`,
          smallQuote: `C'est le support que j'ai le plus utilisé`,
          content: `<p>J'ai commencé à m'entraîner sur EDN des la fin de la D3 et c'est le support que j'ai le plus utilisé pendant la D4.</p><p> Les DP et QI sont très poussés ce qui permet à la fois de s'entraîner sur les points compliqués du programme et de mettre en évidence des points mal compris, une vraie source de motivation pour la poursuite de l'apprentissage !</p><p> La publication de nouveaux dossiers régulièrement permet de rythmer les révisions et de situer son niveau grâce au classement publié.</p><p> Les articles de LCA sont variés, balayent tous les types d'études et permettent de progresser grâce à une correction détaillée !
          </p>`,
          picture: 'assets/images/thumbnails/marie.png',
        },
        // {
        //   author: `Charles-Meyer`,
        //   rank: `Major aux ECNi 2019`,
        //   smallQuote: `Ce site a sans aucun doute contribué à mon résultat final…`,
        //   content: `<p>
        //     J’ai commencé à utiliser EDN de façon assidue en D3.
        //     Cet outil offre une grande variété de contenu, qu’il s’agisse des questions isolées ou des dossiers
        //     progressifs,
        //     dont les corrections sont toujours détaillées.
        //   </p>

        //   <p>
        //     Les concours blancs et les EDNquizz permettent de s’habituer à des dossiers originaux et surprenants comme
        //     ceux
        //     pouvant tomber à l’ECN, et les classements permettent d’avoir une idée de sa progression.<br>
        //     Le mode ancrage des connaissances ® aide également à organiser ses révisions autour de ses points faibles, ce qui
        //     constitue
        //     un atout majeur devant l’ampleur du programme.
        //   </p>

        //   <p>
        //     Ce site a sans aucun doute contribué à mon résultat final et je suis heureux de rentrer dans l’équipe des
        //     conférenciers !
        //   </p>`,
        //   picture: 'assets/images/thumbnails/charles.jpg',
        // },
      ];
    } else if (this.isPrepSSM()) {
      return [
        {
          author: `Donnied`,
          rank: `Neurologia`,
          smallQuote: `L'applicazione è quanto di meglio si possa desiderare per preparare e affrontare il test.`,
          content: `<p>A pochi giorni dal test di specializzazione mi sono riproposto di lasciare una recensione lucida e a freddo di prepssm.L'applicazione è quanto di meglio si possa desiderare per preparare e affrontare il test.<p></p>La difficoltà delle domande permette di abbattere i muri delle proprie certezze e quindi di affrontare al meglio la preparazione di un test con domande rifuardo una scienza non esatta, la medicina a 360 gradi.<p></p>I punti di forza sono innumerevoli. Primo fra tutti il lato umano e la prontezza di risposta dell'help desk. Ho ricevuto risposte live innumerevoli volte su qualsiasi dubbio riguardo la correttezza o meno di alcune risposte e riguardo anche problemi tecnici che sono fisiologicissimi con una portata così elevata di utenti.<p></p>Potrei passare ore ad elogiare l'applicazione e a fare i complimenti a tutto lo staff ma credo sia più importante che rimanga quanto questa applicazione sia CONSIGLIATISSIMA e da consigliare. Grazie.</p>`,
          picture: null,
        },
      ];
    } else if (this.isPrepMIR()) {
      return [];
    }
  }

  landingTestimony(section: string) {
    if (section === 'ancrage') {
      if (this.isEcni()) {
        // return {
        //   author: 'Charles-Meyer',
        //   text: '« Le mode <strong>ancrage des connaissances&nbsp;®</strong> aide également à organiser ses révisions autour de ses points faibles, ce qui constitue un atout majeur devant l’ampleur du programme. ! »',
        //   rank: 'Major aux ECNi 2019',
        // };
        return null;
      } else if (this.isPrepSSM()) {
        return {
          author: 'Nicola',
          text: `“Non avevo mai sentito parlare dell' <strong>ancoraggio mentale</strong>&nbsp;® ma da quando lo uso non ne posso più fare a meno: le mie capacità di ritenere informazioni a lungo termine sono migliorate grazie ai continui stimoli dell’algoritmo”`,
          rank: null,
        };
      } else if (this.isPrepMIR()) {
        return null;
      }
    } else if (section === 'feedbacks') {
      if (this.isEcni()) {
        return {
          author: 'David',
          text: `« La base de donnée est gigantesque, et s'enrichit tout au long de l'année. Les classements sont bien représentatifs, et motivants. Le prix reste modeste en regard des bénéfices que l'on peut en tirer. Je m'y suis inscrit dès le début de la D4, et je ne le regrette pas! »`,
          rank: '3e aux ECNi 2019',
        };
      } else if (this.isPrepSSM()) {
        return {
          author: 'Elvis',
          text: `« Grazie ragazzi per l’opportunità gratuita che ci avete dato.Se ho vinto la borsa che desideravo è sicuramente anche grazie alle vostre domande,sicuramente abbastanza avanzate come difficoltà ma utili e commentate brillantemente,Consigliierei a chiunque questa piattaforma.Continuate così! Ancora grazie.»`,
          rank: null,
        };
      } else if (this.isPrepMIR()) {
        return null;
      }
    } else if (section === 'ranking') {
      if (this.isEcni()) {
        return {
          author: 'Maxime',
          text: `“J’ai commencé à utiliser EDN durant ma D3. Un bon moyen de se familiariser avec les épreuves informatisées. Les concours blancs nationaux permettent de s’entraîner dans des conditions réalistes. Cela permet d’arriver le jour J plus rassuré”`,
          rank: 'Major aux ECNi 2018',
        };
      } else if (this.isPrepSSM()) {
        return {
          author: 'Greta',
          text: `« Ciao! Ci tenevo a ringraziarci per il lavoro che avete fatto.Ho fatto il concorso ed è andato molto bene, a novembre inizierò la specializzazione in ginecologia e ostetricia al Gemelli! I vostri quiz settimanali sono stati davvero importanti per la mia preparazione.Ho usufruito di altri siti che proponevano quiz per prepararsi ma il vostro è senza dubbio il migliore.Continuate così!.»`,
          rank: null,
        };
      } else if (this.isPrepMIR()) {
        return null;
      }
    } else if (section === 'exams') {
      if (this.isEcni()) {
        return {
          author: 'Corentin',
          text: `« Probablement le meilleur site de préparation aux ECN niveau rapport qualité / quantité / prix. Un plus non négligeable pour bien aborder la D4 »`,
          rank: '24ème aux Ecni 2019',
        };
      } else if (this.isPrepSSM()) {
        return null;
      } else if (this.isPrepMIR()) {
        return null;
      }
    } else if (section === 'conftube') {
      if (this.isEcni()) {
        return {
          author: 'Inès',
          text: `" Les Conftubes sont un format bien sympathique en cas de craquage, c'est sympa, c'est rigolo, et sans rire ça éclaire pas mal!"`,
          rank: '98ème aux ECNi 2019',
        };
      } else if (this.isPrepSSM()) {
        return null;
      } else if (this.isPrepMIR()) {
        return null;
      }
    } else if (section === 'training') {
      if (this.isEcni()) {
        return {
          author: 'Simon',
          text: `"Je le recommande ++ notamment des la D2 ou la partie "training" permet de balayer chaque item un par un."`,
          rank: '29ème aux ECNi 2019',
        };
      } else if (this.isPrepSSM()) {
        return {
          author: 'Chiara',
          text: `"Ho trovato molto utile questa app per la preparazione di ssm19: i quiz sono ben fatti e ben spiegati, è utile capire quante persone hanno sbagliato una determinata domanda per capire se si hanno delle lacune importanti nella preparazione"`,
          rank: null,
        };
      } else if (this.isPrepMIR()) {
        return null;
      }
    }
  }

  get examsOnLanding() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get conftubeOnLanding() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get linkToPremiumInPublicTopNav() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get mindmapsEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get lisaEnabled() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get isPediatricEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get premiumEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get testimoniesEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get challengeEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get itemsEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get coursesEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get linkToCoursesEnabled() {
    if (this.isEcni()) {
      return this.coursesEnabled && true;
    } else if (this.isPrepSSM()) {
      return this.coursesEnabled && false;
    } else if (this.isPrepMIR()) {
      return this.coursesEnabled && false;
    }
  }

  get trainingEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get multiRankEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get customExamEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get feedbacksEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get numbersOnLanding() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get showQuestionType() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get oldItemsSupport() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get randomTestimonyOnModalPub() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get firstMultirankYear() {
    if (this.isEcni()) {
      return 2019;
    } else if (this.isPrepSSM()) {
      return 2019;
    } else if (this.isPrepMIR()) {
      return 2020;
    }
  }

  get supportAdditionalTimeInExam() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get displayItemTitleInTraining() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get isNewFicheEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get isR2CEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get rankEcnEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get testimoniesDreamSpecialtyEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get examCustomMaxNbQuestions() {
    if (this.isEcni()) {
      return 140;
    } else if (this.isPrepSSM()) {
      return 140;
    } else if (this.isPrepMIR()) {
      return 250;
    }
  }

  get allowDownloadOnNewPdf() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get itemImageFirstInCoursesList() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get conftubeHasLogo() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get displayItemUpdatedAt() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get isVideoAvailable() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get isVideoRestrictedForElite() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get isVideoInBottomMenu() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get isEasyDPAvailable() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get displaySubscriptionDetailsOnPricingPage() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return true;
    }
  }

  get isEliteEnabled() {
    if (this.isEcni()) {
      return false;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get isEcosEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get isParticipationNumbersEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return true;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  get isRelevanceEnabled() {
    if (this.isEcni()) {
      return true;
    } else if (this.isPrepSSM()) {
      return false;
    } else if (this.isPrepMIR()) {
      return false;
    }
  }

  isExamEnabled(type: string) {
    return getExamType(type).languagesAvailable.includes(environment.defaultLanguage);
  }
}
