import { parseBooleanMember, parseIntMember, parseFloatMember } from '../utils/parse-functions';
import { Answer, CandidateAnswer } from './answer.model';
import { EcniDocument } from './ecni-document.model';
import { Discipline } from './discipline.model';
import { Item } from './item.model';
import { Course, Notion, Rubrique } from './course.model';

export class Question {
  id: number;
  isQI: boolean;
  questionIndex: number;
  type:
    | 'question.match'
    | 'question.one'
    | 'question.tcs'
    | 'question.many'
    | 'question.many_r2c'
    | 'question.shortMenu'
    | 'question.imageRecognition';
  grade: number;
  gradeMax: number;
  moyenne: number;
  nbGoodAnswers: number;

  content: string;
  explaination: string;

  documentsImg: EcniDocument[];
  documentsPdf: EcniDocument[];
  documentsVideo: EcniDocument[];
  documents: EcniDocument[];

  documentsExplainedImg: EcniDocument[];
  documentsExplainedPdf: EcniDocument[];
  documentsExplainedVideo: EcniDocument[];
  documents_explained: EcniDocument[];

  answers: Answer[];

  notions: Notion[];
  lisa: boolean;
  isPediatric: boolean;

  isAnswered: boolean;
  userAnswerMatch: string;
  userAnswerOne: number;
  userAnswerMenu: number[];
  userAnswerZAP: ZAPPoint;

  matchIsCorrect: boolean;
  oneIsCorrect: boolean;
  ZAPIsCorrect: boolean;

  candidateAnswers: CandidateAnswer[];

  isUpdating: boolean;

  display: string;
  value: string;

  message: string;
  success: boolean;

  disciplines: Discipline[];
  items: Item[];

  answersCorrection: AnswersCorrection | ConftubeAnswersCorrection;

  was_planned: boolean;
  number: number;

  imageUrl: string;

  nbFeedbacks = -1;

  constructor(input: any) {
    if (input.grade_max) {
      input.gradeMax = input.grade_max;
    }

    parseIntMember(input, 'id');
    parseBooleanMember(input, 'isQi');
    parseIntMember(input, 'questionIndex');
    parseFloatMember(input, 'grade');
    parseFloatMember(input, 'gradeMax');
    parseFloatMember(input, 'moyenne');
    parseIntMember(input, 'nbGoodAnswers');

    parseBooleanMember(input, 'isAnswered');
    parseIntMember(input, 'userAnswerOne');
    parseBooleanMember(input, 'isUpdating');

    parseBooleanMember(input, 'lisa');
    parseBooleanMember(input, 'isPediatric');

    parseIntMember(input, 'next_date');
    parseBooleanMember(input, 'success');
    parseIntMember(input, 'already_asked');
    parseIntMember(input, 'ask_now_nb');

    parseBooleanMember(input, 'was_planned');

    Object.assign(this, input);

    if (this.answers) {
      this.answers = this.answers.map((answer) => {
        return new Answer(answer);
      });
    } else {
      this.answers = [];
    }

    if (this.candidateAnswers) {
      this.candidateAnswers = this.candidateAnswers.map((candidateAnswer) => {
        return new CandidateAnswer(candidateAnswer);
      });
    } else {
      this.candidateAnswers = [];
    }

    if (this.documentsImg) {
      this.documentsImg = this.documentsImg.map((document) => {
        return new EcniDocument(document);
      });
    } else {
      this.documentsImg = [];
    }

    if (this.documentsPdf) {
      this.documentsPdf = this.documentsPdf.map((document) => {
        return new EcniDocument(document);
      });
    } else {
      this.documentsPdf = [];
    }

    if (this.documentsVideo) {
      this.documentsVideo = this.documentsVideo.map((document) => {
        return new EcniDocument(document);
      });
    } else {
      this.documentsVideo = [];
    }

    if (this.documents) {
      this.documents = this.documents.map((document) => {
        return new EcniDocument(document);
      });
    } else {
      this.documents = [];
    }

    if (this.documentsExplainedImg) {
      this.documentsExplainedImg = this.documentsExplainedImg.map((document) => {
        return new EcniDocument(document);
      });
    } else {
      this.documentsExplainedImg = [];
    }

    if (this.documentsExplainedPdf) {
      this.documentsExplainedPdf = this.documentsExplainedPdf.map((document) => {
        return new EcniDocument(document);
      });
    } else {
      this.documentsExplainedPdf = [];
    }

    if (this.documentsExplainedVideo) {
      this.documentsExplainedVideo = this.documentsExplainedVideo.map((document) => {
        return new EcniDocument(document);
      });
    } else {
      this.documentsExplainedVideo = [];
    }

    if (this.documents_explained) {
      this.documents_explained = this.documents_explained.map((document) => {
        return new EcniDocument(document);
      });
    } else {
      this.documents_explained = [];
    }

    if (this.disciplines) {
      this.disciplines = this.disciplines.map((discipline) => {
        return new Discipline(discipline);
      });
    } else {
      this.disciplines = [];
    }

    if (this.items) {
      this.items = this.items.map((item) => {
        return new Item(item);
      });
    } else {
      this.items = [];
    }

    if (this.notions) {
      this.notions = this.notions.map((notion) => {
        return new Notion(notion);
      });
    } else {
      this.notions = [];
    }

    if (this.answersCorrection) {
      this.answersCorrection = new AnswersCorrection(this.answersCorrection);
    }
  }

  get harderNotion() {
    if (this.notions.length) {
      const notionsSorted = this.notions.sort((a, b) => {
        return a.rang < b.rang ? 1 : -1;
      });
      return notionsSorted[0];
    } else {
      return null;
    }
  }

  get notionsTree() {
    const items: Course[] = [];
    this.notions.forEach((notion) => {
      if (notion.rubrique && notion.rubrique.item) {
        const itemIndex = items.findIndex((item) => item.id === notion.rubrique.item.id);
        if (itemIndex === -1) {
          items.push(
            new Course({
              id: notion.rubrique.item.id,
              title: notion.rubrique.item.title,
              number: notion.rubrique.item.number,
              disciplines: notion.rubrique.item.disciplines,
              rubriques: [
                new Rubrique({
                  id: notion.rubrique.id,
                  title: notion.rubrique.title,
                  notions: [notion],
                }),
              ],
            })
          );
        } else {
          const rubriqueIndex = items[itemIndex].rubriques.findIndex(
            (rubrique) => rubrique.id === notion.rubrique.id
          );
          if (rubriqueIndex === -1) {
            items[itemIndex].rubriques.push(
              new Rubrique({
                id: notion.rubrique.id,
                title: notion.rubrique.title,
                notions: [notion],
              })
            );
          } else {
            items[itemIndex].rubriques[rubriqueIndex].notions.push(notion);
          }
        }
      }
    });
    return items;
  }

  get acceptedAnswers() {
    return this.answers.map((answer) => answer.content).join(', ');
  }
}

export class ConftubeQuestion {
  grade: number;
  gradeMax: number;
  question: Question;

  constructor(input: any) {
    parseFloatMember(input, 'grade');
    parseFloatMember(input, 'gradeMax');
    Object.assign(this, input);

    if (this.question) {
      this.question = new Question(this.question);
    }
  }
}

export class AnswersCorrection {
  correctAnswers: Answer[];
  answerIsCorrect: boolean;
  candidateQuestion: Question;

  constructor(input: any) {
    if (input.correctAnswers === true) {
      this.answerIsCorrect = true;
    } else if (input.correctAnswers?.correct === true) {
      this.answerIsCorrect = true;
      input.correctAnswers = input.correctAnswers.answer;
    } else {
      this.answerIsCorrect = false;
    }

    Object.assign(this, input);

    if (this.correctAnswers && typeof this.correctAnswers !== 'boolean') {
      this.correctAnswers = this.correctAnswers.map((correctAnswer) => {
        return new Answer(correctAnswer);
      });
    } else {
      this.correctAnswers = null;
    }

    if (this.candidateQuestion) {
      this.candidateQuestion = new Question(this.candidateQuestion);
    }
  }
}

export class ConftubeAnswersCorrection {
  correctAnswers: Answer[];
  answerIsCorrect: boolean;
  candidateQuestion: ConftubeQuestion;

  constructor(input: any) {
    if (input.correctAnswers === true) {
      this.answerIsCorrect = true;
    } else if (input.correctAnswers?.correct === true) {
      this.answerIsCorrect = true;
      input.correctAnswers = input.correctAnswers.answer;
    } else {
      this.answerIsCorrect = false;
    }

    Object.assign(this, input);

    if (this.correctAnswers && typeof this.correctAnswers !== 'boolean') {
      this.correctAnswers = this.correctAnswers.map((correctAnswer) => {
        return new Answer(correctAnswer);
      });
    } else {
      this.correctAnswers = null;
    }

    if (this.candidateQuestion) {
      this.candidateQuestion = new ConftubeQuestion(this.candidateQuestion);
    }
  }
}

export class CandidateQuestion {
  grade: number;
  grade_max: number;
  question: number;

  constructor(input: any) {
    parseFloatMember(input, 'grade');
    parseFloatMember(input, 'gradeMax');
    parseIntMember(input, 'question');
    Object.assign(this, input);
  }
}

export class ZAPPoint {
  x: number;
  y: number;
}
