import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import {
  AppleSigninPayload,
  CheckEmailResult,
  CheckUsernameResult,
  FacebookConnectResult,
  LoginPayload,
  LoginResult,
  PatchPayload,
  PatchResult,
  RefreshTokenPayload,
  RegisterPayload,
  RegisterResult,
  ResetEmailPayload,
  ResetEmailResult,
  ResetPasswordPayload,
  SocialLoginPayload,
  UserProfileResult,
  UserResult,
} from '../../../shared/models/auth.model';
import { QueryCollection } from '../../../shared/models/collection.model';
import { EcniUser } from '../../../shared/models/ecni-user.model';
import { EcniSubscription, Invoice, Refund } from '../../../shared/models/premium.model';
import { QueryData } from './../../../shared/models/query-data.model';

import { UserEndpoints } from '../../../shared/endpoints/user.endpoints';
import { FacebookAuthResult } from '../../../shared/models/facebook.model';

@Injectable({
  providedIn: 'root',
})
export class HttpUserService {
  constructor(private http: HttpClient) {}

  login(username: string, password: string) {
    const data: LoginPayload = {
      username,
      password,
      client_id: environment.auth_client_id,
      client_secret: environment.auth_client_secret,
      grant_type: 'password',
    };

    return this.http.post(UserEndpoints.getOauth2Token, data).pipe(
      map((res) => {
        return new LoginResult(res);
      })
    );
  }

  googleFbLogin(accessToken: string, type: string) {
    let grantType;
    if (type === 'google') {
      grantType = environment.auth_google_grant_type;
    } else if (type === 'facebook') {
      grantType = environment.auth_fb_grant_type;
    }

    const data: SocialLoginPayload = {
      access_token: accessToken,
      client_id: environment.auth_client_id,
      client_secret: environment.auth_client_secret,
      grant_type: grantType,
    };

    return this.http.post(UserEndpoints.getOauth2Token, data).pipe(
      map((res) => {
        return new LoginResult(res);
      })
    );
  }

  facebookLimitedLogin(facebookAuthResult: FacebookAuthResult) {
    return this.http
      .post(UserEndpoints.facebookLimitedSignin, {
        token: facebookAuthResult.authResponse.accessToken,
      })
      .pipe(
        map((res) => {
          return new LoginResult(res);
        })
      );
  }

  googleLogin(googleAuth: any) {
    return this.http.post(UserEndpoints.googleSignin, googleAuth).pipe(
      map((res) => {
        return new LoginResult(res);
      })
    );
  }

  appleLogin(data: AppleSigninPayload) {
    return this.http.post(UserEndpoints.appleSignin, data).pipe(
      map((res) => {
        return new LoginResult(res);
      })
    );
  }

  register(registerCredentials: RegisterPayload) {
    return this.http.post(UserEndpoints.register, registerCredentials).pipe(
      map((res) => {
        return new RegisterResult(res);
      })
    );
  }

  sendResetEmail(email: string) {
    const data: ResetEmailPayload = {
      username: email,
    };

    return this.http.post(UserEndpoints.sendResetEmail, data).pipe(
      map((res: any) => {
        return new ResetEmailResult(res);
      })
    );
  }

  resetPassword(resetToken: string, newPassword: string) {
    const data: ResetPasswordPayload = {
      password: newPassword,
    };

    return this.http.post(UserEndpoints.resetPassword + '/' + resetToken, data);
  }

  confirmUser(confirmToken: string) {
    return this.http.post(UserEndpoints.confirmUser + '/' + confirmToken, {}).pipe(
      map((res) => {
        return new LoginResult(res);
      })
    );
  }

  checkEmail(email: string) {
    const params = {
      email,
    };

    return this.http.get(UserEndpoints.checkEmail, { params }).pipe(
      map((res) => {
        return new CheckEmailResult(res);
      })
    );
  }

  checkUsername(username: string) {
    const params = {
      username,
    };

    return this.http.get(UserEndpoints.checkUsername, { params }).pipe(
      map((res) => {
        return new CheckUsernameResult(res);
      })
    );
  }

  patchUser(patchCredentials: PatchPayload) {
    return this.http.patch(UserEndpoints.user, patchCredentials).pipe(
      map((res) => {
        return new PatchResult(res);
      })
    );
  }

  refreshAuthToken(refreshToken: string) {
    const data: RefreshTokenPayload = {
      refresh_token: refreshToken,
      client_id: environment.auth_client_id,
      client_secret: environment.auth_client_secret,
      grant_type: 'refresh_token',
    };

    return this.http.post(UserEndpoints.getOauth2Token, data).pipe(
      map((res) => {
        return new LoginResult(res);
      })
    );
  }

  getUserProfile() {
    return this.http.get(UserEndpoints.userProfile).pipe(
      map((res) => {
        return new UserProfileResult(res);
      })
    );
  }

  screenshotTaken() {
    return this.http.get(UserEndpoints.screenshotTaken);
  }

  getUser(id: number) {
    return this.http.get(UserEndpoints.get(id)).pipe(
      map((res) => {
        return new UserResult(res);
      })
    );
  }

  // facebookConnect(facebookId: string, facebookAccessToken: string) {
  //   const params = {
  //     facebookId,
  //     facebookAccessToken,
  //   };
  //   return this.http.get(UserEndpoints.facebookConnect, { params }).pipe(
  //     map((res) => {
  //       return new FacebookConnectResult(res);
  //     })
  //   );
  // }

  quit() {
    return this.http.get(UserEndpoints.quit);
  }

  search(options: QueryData) {
    return this.http.get(UserEndpoints.searchUser, { params: options as HttpParams }).pipe(
      map((res: any) => {
        res.data = res.data.map((raw) => {
          return new EcniUser(raw);
        });
        return new QueryCollection<EcniUser>(res);
      })
    );
  }

  resetData() {
    return this.http.get(UserEndpoints.resetData);
  }

  getSubscription() {
    return this.http.get(UserEndpoints.subscription).pipe(
      map((res) => {
        return new EcniSubscription(res);
      })
    );
  }

  getInvoices() {
    return this.http.get(UserEndpoints.invoices).pipe(
      map((res: any) => {
        res.data = res.data.map((raw) => {
          return new Invoice(raw);
        });
        return res.data as Invoice[];
      })
    );
  }

  getRefunds() {
    return this.http.get(UserEndpoints.refunds).pipe(
      map((res: any) => {
        res = res.map((raw) => {
          return new Refund(raw);
        });
        return res as Refund[];
      })
    );
  }

  uploadAvatarBase64(image) {
    const data = {
      file64: image,
    };

    return this.http.post(UserEndpoints.uploadAvatar, data);
  }

  uploadAvatarFormData(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(UserEndpoints.uploadAvatar, formData);
  }

  deleteAvatar() {
    return this.http.delete(UserEndpoints.deleteAvatar);
  }
}
