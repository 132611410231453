import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of, BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { get } from 'scriptjs';

import { environment } from './../../../environments/environment';

import { FacebookAuthResult } from '../../shared/models/facebook.model';

import { IsApplicationService } from './is-application.service';
import { FacebookLogin } from '@whiteguru/capacitor-plugin-facebook-login';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';

@Injectable({
  providedIn: 'root',
})
export class FacebookToEcniService {
  isLoaded = new BehaviorSubject<boolean>(false);
  isLoading = false;

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    private isApplicationService: IsApplicationService
  ) {}

  init(): Observable<boolean> {
    if (!this.isApplicationService.isApplication()) {
      return new Observable((observer) => {
        get('https://connect.facebook.net/' + environment.locale + '/sdk.js', () => {
          (window as any).fbAsyncInit = () => {
            FB.init({
              appId: environment.fb_id,
              cookie: true,
              xfbml: true,
              version: 'v17.0',
            });

            observer.next(true);
          };
        });
      });
    } else {
      return of(true);
    }
  }

  initialized() {
    if (!this.isLoaded.getValue()) {
      if (!this.isLoading) {
        return this.init().pipe(
          tap(() => {
            this.isLoading = false;
            this.isLoaded.next(true);
          })
        );
      } else {
        return this.isLoaded.pipe(
          filter((isLoaded) => !!isLoaded),
          take(1)
        );
      }
    } else {
      return of(true);
    }
  }

  facebookLogin(): Observable<{
    facebookAuthResult: FacebookAuthResult;
    loginType: 'default' | 'limited';
  }> {
    if (this.isApplicationService.isApplication()) {
      return this.facebookLoginApplication();
    } else {
      return this.facebookLoginDesktop();
    }
  }

  facebookLoginApplication(): Observable<{
    facebookAuthResult: FacebookAuthResult;
    loginType: 'default' | 'limited';
  }> {
    return new Observable((observer) => {
      FacebookLogin.getCurrentAccessToken().then(
        (resStatus) => {
          if (resStatus && resStatus.accessToken) {
            observer.next({
              facebookAuthResult: {
                authResponse: {
                  accessToken: resStatus.accessToken.token,
                  userID: resStatus.accessToken.userId,
                },
              },
              loginType: 'default',
            });
          } else {
            if (this.isApplicationService.isAppIos()) {
              AppTrackingTransparency.getStatus().then((status) => {
                if (status.status === 'authorized') {
                  this.doFacebookApplicationLogin(observer);
                } else {
                  this.doFacebookApplicationLimitedLogin(observer);
                }
              });
            } else {
              this.doFacebookApplicationLogin(observer);
            }
          }
        },
        (err) => {
          this.toastr.warning(this.translate.instant('util.error') + '-errFBCO3');
          observer.error(err);
        }
      );
    });
  }

  doFacebookApplicationLogin(
    observer: Subscriber<{
      facebookAuthResult: FacebookAuthResult;
      loginType: 'default' | 'limited';
    }>
  ) {
    FacebookLogin.login({ permissions: ['public_profile', 'email'] }).then(
      (resLogin) => {
        if (resLogin?.accessToken) {
          observer.next({
            facebookAuthResult: {
              authResponse: {
                accessToken: resLogin.accessToken.token,
                userID: resLogin.accessToken.userId,
              },
            },
            loginType: 'default',
          });
        } else {
          this.toastr.warning(this.translate.instant('util.error') + '-errFBCO1');
          observer.error('FBERROR: no resLogin received');
        }
      },
      (err) => {
        this.toastr.warning(this.translate.instant('util.error') + '-errFBCO2');
        observer.error(err);
      }
    );
  }

  doFacebookApplicationLimitedLogin(
    observer: Subscriber<{
      facebookAuthResult: FacebookAuthResult;
      loginType: 'default' | 'limited';
    }>
  ) {
    FacebookLogin.loginWithLimitedTracking({ permissions: ['public_profile', 'email'] }).then(
      (resLogin) => {
        if (resLogin?.authenticationToken) {
          observer.next({
            facebookAuthResult: {
              authResponse: {
                accessToken: resLogin.authenticationToken.token,
                userID: resLogin.authenticationToken.userId,
              },
            },
            loginType: 'limited',
          });
        } else {
          this.toastr.warning(this.translate.instant('util.error') + '-errFBCLLO1');
          observer.error('FBERROR: no resLogin received');
        }
      },
      (err) => {
        this.toastr.warning(this.translate.instant('util.error') + '-errFBCLLO2');
        observer.error(err);
      }
    );
  }

  facebookLoginDesktop(): Observable<{
    facebookAuthResult: FacebookAuthResult;
    loginType: 'default' | 'limited';
  }> {
    return new Observable((observer) => {
      this.initialized().subscribe(() => {
        FB.getLoginStatus((resStatus) => {
          if (resStatus.status && resStatus.status === 'connected') {
            observer.next({
              facebookAuthResult: resStatus as FacebookAuthResult,
              loginType: 'default',
            });
          } else {
            let scope = 'public_profile,email';
            if (environment.env === 'dev') {
              scope = 'public_profile,email,user_friends';
            }
            FB.login(
              (resLogin) => {
                observer.next({
                  facebookAuthResult: resLogin as FacebookAuthResult,
                  loginType: 'default',
                });
              },
              { scope }
            );
          }
        });
      });
    });
  }
}
