<div class="question-challenge">
  <div class="top-question-challenge" [class.-inside]="animationState.questionTop">
    <div class="discipline-badge -inside" *ngIf="exam?.disciplines?.length">
      <div class="badge-content">
        <svg-icon
          src="assets/images/illustrations/disciplines/{{
            disciplinesService.getIconId(exam.disciplines[0].id)
          }}svg.svg"
        ></svg-icon>
        <span>{{ exam.disciplines[0].name }}</span>
      </div>
    </div>

    <div class="widget-time-remaining">
      <span class="text" [class.-finished]="timeRemaining <= 0">
        <ng-container *ngIf="timeRemaining > 0">
          {{ 'examens.il_reste' | translate }}
          <span class="sm:hidden">
            {{ ceiledCountdown | number : '0.0-0' : 'fr' }}
            <ng-container *ngIf="timeRemaining <= 60">
              {{ 'examens.min' | translate }}
            </ng-container>
            <ng-container *ngIf="timeRemaining > 60">
              {{ 'examens.mins' | translate }}
            </ng-container>
          </span>
        </ng-container>
        <ng-container *ngIf="timeRemaining <= 0">
          {{ 'training.temps_ecoule2' | translate }}
        </ng-container>
      </span>
      <div class="ecni_progress_bar">
        <div
          class="progress_bar_in"
          [class.-green]="timeRemaining > 0"
          [class.-red]="timeRemaining <= 0"
          [style.width.%]="progressBarPercentage"
        ></div>
      </div>
      <span class="text hidden sm:block">
        <ng-container *ngIf="timeRemaining > 0">
          {{ ceiledCountdown | number : '0.0-0' : 'fr' }}
          <ng-container *ngIf="timeRemaining <= 60">
            {{ 'examens.min' | translate }}
          </ng-container>
          <ng-container *ngIf="timeRemaining > 60">
            {{ 'examens.mins' | translate }}
          </ng-container>
        </ng-container>
      </span>
    </div>
  </div>

  <div
    class="content-question-challenge -in-candidate"
    [class.-inside]="animationState.questionContent"
  >
    <div class="question-number" [class.-inside]="animationState.questionNumber">
      {{ 'question.question' | translate }} {{ currentQuestion.questionIndex + 1 }}
    </div>

    <div class="question-content" [class.-inside]="animationState.questionText">
      <app-question-images
        [documents]="currentQuestion.documentsImg"
        *ngIf="currentQuestion.documentsImg.length"
      ></app-question-images>

      <div
        class="question-text"
        [innerHTML]="currentQuestion.content"
        appCopyRestricted
        [copyBlocked]="!userService.isPremiumPlus()"
      ></div>

      <div class="block-videos-list" *ngIf="currentQuestion.documentsVideo.length">
        <div
          [innerHTML]="video.embed | safe : 'html'"
          *ngFor="let video of currentQuestion.documentsVideo"
          class="block-iframe"
        ></div>
      </div>

      <div class="pdf-question mt-8" *ngIf="currentQuestion.documentsPdf.length">
        <ng-container *ngFor="let pdf of currentQuestion.documentsPdf">
          <app-pdf-viewer [url]="pdf.fileUrl" [initialHeight]="'70vh'"></app-pdf-viewer>
        </ng-container>
      </div>
    </div>

    <div
      class="question-answers"
      *ngIf="
        currentQuestion.type == 'question.one' ||
        currentQuestion.type == 'question.tcs' ||
        currentQuestion.type == 'question.many' ||
        currentQuestion.type == 'question.many_r2c' ||
        currentQuestion.type == 'question.shortMenu'
      "
      [class.-inside]="animationState.questionAnswers"
    >
      <div
        class="question-answer"
        *ngFor="let answer of currentQuestion.answers"
        [class.-checked]="answer.userChecked"
        [class.-correct]="isCorrection && showCorrection && answerIsCorrect(answer)"
        [class.-incorrect]="isCorrection && showCorrection && !answerIsCorrect(answer)"
        (click)="checkAnswer(answer)"
        [style.order]="answer.randomOrder"
      >
        {{ answer.content | utf8_encode }}
      </div>
    </div>

    <div
      class="question-qroc"
      *ngIf="currentQuestion.type == 'question.match'"
      [class.-inside]="animationState.questionQroc"
    >
      <form
        class="form-nnki form-nnki-candidate -candidate"
        [class.nnki-no-select]="isCorrection"
        (ngSubmit)="validateAnswer()"
      >
        <div class="row-form-nnki">
          <input
            type="text"
            class="input-nnki width100"
            placeholder="{{ 'util.saisissez' | translate }}"
            [(ngModel)]="currentQuestion.userAnswerMatch"
            name="userAnswer-{{ currentQuestion.id }}"
            [class.-correct]="isCorrection && showCorrection && currentQuestion.matchIsCorrect"
            [class.-incorrect]="isCorrection && showCorrection && !currentQuestion.matchIsCorrect"
          />
        </div>

        <ng-container *ngIf="isCorrection && !currentQuestion.matchIsCorrect">
          <div class="row-form-nnki" *ngFor="let answer of answersCorrection.correctAnswers">
            <input
              type="text"
              class="input-nnki width100 -accepted"
              placeholder="{{ 'util.saisissez' | translate }}"
              [value]="answer.content | utf8_encode"
            />
          </div>
        </ng-container>
      </form>
    </div>

    <div
      class="zap_canvas_block"
      *ngIf="currentQuestion.type == 'question.imageRecognition'"
      [class.-inside]="animationState.questionZap"
    >
      <div class="zap_canvas_container" (click)="selectPoint($event)" #canvasContainer>
        <canvas #canvas class="zap_canvas"></canvas>
        <img [src]="currentQuestion.imageUrl" #imageZAP class="zap_image" (load)="initCanvas()" />
      </div>
    </div>

    <div
      class="question-button"
      [class.-inside]="animationState.questionButton"
      *ngIf="!isCorrection"
    >
      <button
        matRipple
        [matRippleCentered]="false"
        [matRippleUnbounded]="true"
        [matRippleRadius]="50"
        class="btn-nnki btn-nnki-primary btn-nnki-uppercase"
        *ngIf="!validatingAnswer"
        (click)="validateAnswer()"
      >
        {{ 'util.valider' | translate }}
      </button>

      <div class="spinner_100_container" *ngIf="validatingAnswer">
        <app-loader-dots></app-loader-dots>
      </div>
    </div>
  </div>
</div>
